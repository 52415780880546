import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { API_BASE_URL } from "../config/config";
import PageLoader from '../components/home/pageload';
import axios from "axios";
import { Routes, Route } from "react-router-dom";
import Home from '../pages/index';
import Location from '../pages/location';
import NetworkServices from '../pages/network-service';
import CyberSecurityServices from '../pages/cybersecurity-services';
import DataCenterServices from '../pages/data-center-services';
import SystemsIntegration from '../pages/systems-integration';
import EnterpriseConnectivity from '../pages/network-services/enterprise-connectivity';
import Unifiedommunication from '../pages/network-services/unified-communication';
import HighSpeedBroadband from '../pages/network-services/high-speed-broadband';
import CarrierBasedCloudInterconnect from '../pages/network-services/carrier-based-cloud';
import SecureAndScalableDigital from '../pages/systems-integration/secure-and-scalable-digital';
import DataLifeCycleSolutions from '../pages/systems-integration/data-lifecycle-solutions';
import ManagedServices from '../pages/systems-integration/managed-services';
import SmartCityServices from '../pages/systems-integration/smart-city-services';
import Contact from '../pages/contact';
import ContactForCustomerService from '../pages/contacts/contact-for-customer-service';
import ContactforSales from '../pages/contacts/contact-for-sales';
import PartnerWithUs from '../pages/contacts/partner-with-us';
import MediaInquires from '../pages/contacts/media-inquires';
import AboutUs from '../pages/about-us';
import NodalContacts from '../pages/contacts/nodal-and-appellate-contact';
import DataCenterServicesColocation from '../pages/data-center-services/colocation';
import ColocationChennai from '../pages/colocation-service/chennai';
import ColocationGandhinagar from '../pages/colocation-service/gandhinagar';
import ColocationMumbai from '../pages/colocation-service/mumbai';

import InternetService from '../pages/internet-services/index.js';
import ServerStorage from '../pages/dell/server-storage.js';

import CloudPlatform from '../pages/data-center-services/cloud-platform';
import CloudServices from '../pages/data-center-services/cloud-services';
import Plans from '../pages/network-services/plans';
import OurCustomers from '../pages/our-customers';
import PrivacyPolicy from '../pages/privacy-policy';
import OurQualityPolicy from '../pages/our-quality-policy';
import Events from '../pages/events';
import IshanCareers from '../pages/careers';
import ErrorPage from '../pages/404-error';
import LatestUpdates from '../pages/latest-updates';
import EventsDetails from '../pages/events/events-details';
import LatestUpdatesDetails from '../pages/latest-updates/latest-updates-details';
import OurPartners from '../pages/our-partners';
import DownloadsResources from '../pages/downloads-resources';
import TermsAndCondition from '../pages/terms-and-condition';
import AwardsPage from '../pages/awards/index.js';
const MyRouter = () => {

  const [headerFooter,setHeaderFooter] = useState(null);
useEffect(()=>{
  getData();
},[])
const getData = () => {
  axios.post(`${API_BASE_URL}/api/frontend/getHeaderFooter`).then((response)=>{

    setHeaderFooter(response);

      }).catch((error)=>{
       <PageLoader />
     })
}
    return(
     <>
     <Layout homeData={headerFooter}>
    <Routes>
      
      <Route path="/" element={<Home />}/>
      <Route path="/location" element={<Location />}/>
      <Route path="/network-services" element={<NetworkServices />}/>
      <Route path="/cybersecurity-services" element={<CyberSecurityServices />}/>
      <Route path="/data-center-services" element={<DataCenterServices />}/>
      <Route path="/systems-integration" element={<SystemsIntegration />}/>
      <Route path="/network-services/enterprise-connectivity" element={<EnterpriseConnectivity />}/>
      <Route path="/network-services/unified-communication" element={<Unifiedommunication />}/>
      <Route path="/network-services/carrier-based-cloud" element={<CarrierBasedCloudInterconnect />}/>
      <Route path="/network-services/high-speed-broadband" element={<HighSpeedBroadband />}/>
      <Route path="/systems-integration/secure-and-scalable-digital" element={<SecureAndScalableDigital />}/>
      <Route path="/systems-integration/data-lifecycle-solutions" element={<DataLifeCycleSolutions />}/>
      <Route path="/systems-integration/smart-city-services" element={<SmartCityServices />}/>
      <Route path="/systems-integration/managed-services" element={<ManagedServices />}/>
      <Route path="/contact" element={<Contact />}/>
      <Route path="/contact/contact-for-sales" element={<ContactforSales />}/>
      <Route path="/contact/contact-for-customer-service" element={<ContactForCustomerService />}/>
      <Route path="/contact/partner-with-us" element={<PartnerWithUs />}/>
      <Route path="/contact/media-inquires" element={<MediaInquires />}/>
      <Route path="/about-us" element={<AboutUs />}/>
      <Route path="/nodal-and-appellate-contact" element={<NodalContacts />}/>

      <Route path="/data-center-services/colocation" element={<DataCenterServicesColocation />}/>
      <Route path="/data-center-services/colocation/chennai" element={<ColocationChennai />}/>
      <Route path="/data-center-services/colocation/mumbai" element={<ColocationMumbai />}/>
      <Route path="/data-center-services/colocation/gandhinagar" element={<ColocationGandhinagar />}/>

      <Route path="/enterprise-connectivity/internet-leased-line-service" element={<InternetService />}/>
      <Route path="/dell/servers-storage" element={<ServerStorage />}/>

      <Route path="/award/frost-and-sullivan-2024-india-network-services" element={<AwardsPage />}/>

      <Route path="/data-center-services/cloud-platform" element={<CloudPlatform />}/>
      <Route path="/data-center-services/cloud-services" element={<CloudServices />}/>
      <Route path="/network-services/high-speed-broadband/plans" element={<Plans />}/>
      <Route path="/our-customers" element={<OurCustomers />}/>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
      <Route path="/our-quality-policy" element={<OurQualityPolicy />}/>
      <Route path="/events" element={<Events />}/>
      <Route path="/careers" element={<IshanCareers />}/>
      <Route path="/404" element={<ErrorPage />}/>
      <Route path="/latest-updates" element={<LatestUpdates />}/>  
      <Route path="/events/:slug" element={<EventsDetails />}/>  
      <Route path="/latest-updates/:slug" element={<LatestUpdatesDetails />}/>  
      <Route path="/latest-updates" element={<LatestUpdates />}/>
      <Route path="/our-partners" element={<OurPartners />}/>
      <Route path="/downloads-resources" element={<DownloadsResources />}/>
      <Route path="/latest-updates/latest-updates-details" element={<LatestUpdatesDetails />}/>
      <Route path="/terms-and-condition" element={<TermsAndCondition />}/>
      <Route path="*" element={<ErrorPage />} /> 
    </Routes>
    </Layout>
  </>
  )
};
export default MyRouter;

