import React, { useEffect, useState } from "react";
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import ClientExperiences from "../../components/home/client-experiences";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import PageLoader from "../../components/home/pageload";
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgSmartCity from "../../assest/images/IshanSmartCity.png";
import ImgDedicatedLeasedLine from "../../assest/images/dedicated-leased-line-ic.svg";
import ImgOfficeInternet from "../../assest/images/office-internet-ic.svg";
import ImgIshanLeasedLine from "../../assest/images/ishan-leased-line-ic.svg";
import awardIMG from "../../assest/images/award-img.png";
import awardBanner from "../../assest/images/award-banner.png";
import btmBanner from "../../assest/images/btm_banner.png";

import kfOne from "../../assest/images/kf1.svg";
import kfTwo from "../../assest/images/kf2.svg";
import kfThree from "../../assest/images/kf-3.svg";
import pdfAward from "../../assest/images/Ishan-Technologies_Award-Writeup.pdf";

import PlansPopup from "../contacts/plans-popup";

import { Link } from "react-router-dom";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";
import ColocationPopup from "../../components/colocation-popup";
import InternetServiceSec from "../../components/interner-service";
import {
     Input, 
     FormFeedback,
     Form, 
     Modal,
     ModalBody,
   } from "reactstrap";

export default function TestPage() {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     const [InternetService, setInternetService] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     useEffect(() => {
          const requestData = {  
               "service_tag": "awards_services"
          };

          axios.post(`${API_BASE_URL}/api/smartcity/getsmartCity`, requestData).then((response) => {
               setInternetService(response);

               console.log('response',response?.data?.data?.smart_city?.smart_city_banner_data[0]);

               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
               const InternetServicesSettingsData = generalSettingsData.find((item) => item.tag === "smart_city_advantage") || null;
               const InternetServicesSettingsResponse = JSON.parse(InternetServicesSettingsData?.response || "{}");

               InternetServicesSettingsResponse.advantages = InternetServicesSettingsResponse;
               setSettingResponse(InternetServicesSettingsResponse);
          }).catch((error) => { 
               <PageLoader />
          })
     }, [])

     const [modal, setModal] = useState(false);
     const [colocationData, setcolocationData] = useState(null);
     const toggle = () => {
       if (modal) {
         setModal(false);
       } else {
         setModal(true);
       }
     };
   
   
     const openModal = (colocationData) => {
       setModal(true);
       setcolocationData(colocationData);
     };   


     const [getPlanModal, setPlanModal] = useState(false);
     const [planData, setPlanData] = useState(null);
     const planToggle = () => {
       if (getPlanModal) {
          setPlanModal(false);
       } else {
          setPlanModal(true);
       }
     };
    
   
     const openPlanModal = (planData) => {
          setPlanModal(true);
          setPlanModal(planData);
     };
   

     const [getInTuchPlanModal, setInTuchPlanModal] = useState(false);
     const planGetIntuchToggle = () => {
       if (getInTuchPlanModal) {
          setInTuchPlanModal(false);
       } else {
          setInTuchPlanModal(true);
       }
     };
    
   
     const openGetInTuchPlanModal = () => {
          setInTuchPlanModal(true);
     };
   

     return (

          <>
               {InternetService === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={InternetService?.data?.data?.smart_city?.smart_city_banner_data
                              [0].title} url='https://ishantechnologies.com/systems-integration/smart-city-services' parentTitle='System Integration' parentUrl='https://ishantechnologies.com/systems-integration' />
                    <MetaTag data={InternetService} pageKey="smart_city"/>
                         {/* <div 
                              className={`video hero-banner internet-banner ${
                              !InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                                                      [0]?.banner_title &&!InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                                                      [0]?.banner_img_desc 
                                   ? 'no-content' 
                                   : ''
                              }`}
                              >
                              <FrontVideo homeData={InternetService} pageKey="smart_city" />

                              <div className="banner-content">
                                   <div className="content-blk">
                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_title && (
                                             <h2 className='home-heading'>
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].banner_title}
                                             </h2>
                                             )}

                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_img_desc && (
                                             <p className="para">
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].banner_img_desc}
                                             </p>
                                             )}

                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name && (
                                             <a href={InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].button_url}>
                                                  <button className="button hero-btn">
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name}
                                                  </button>
                                             </a>
                                             )}
                                   </div>
                              </div>  
                         </div> */}
                         {InternetService?.data?.data?.smart_city?.smart_city_banner_data[0]?.is_video == 1? (
                              <FrontVideo homeData={InternetService} pageKey="smart_city" />
                         ) : ( 
                         <div className="award-banner"  style={
                                   InternetService?.data?.data?.smart_city?.smart_city_banner_data[0]['image_video'] &&
                                   /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(
                                        InternetService.data.data.smart_city.smart_city_banner_data[0]['image_video']
                                   )
                                        ? {
                                             backgroundImage: `url(${InternetService.data.data.smart_city.smart_city_banner_data[0]['image_video']})`,
                                        }
                                        : {
                                             background: 'linear-gradient(102deg, #124178 -1.12%, rgba(182, 49, 45, 0.90) 100%)',
                                        }
                                   }>
                              <div className="container w-100">
                                   <div className="banner-row">
                                        <div className="left-content">
                                             <h2 className="title">
                                             Proudly Recognised
                                             For <span>Excellence</span> 
                                             </h2>
                                             <p className="para">
                                             Ishan Technologies wins the prestigious Frost & Sullivan 2024 Best Practices Award for India Network Services -
                                             <span> Emerging Company of the Year</span>
                                             </p>
                                        </div>
                                        <div className="right-content">
                                             <img src={awardBanner} alt="" />
                                        </div>
                                   </div>
                              </div>
                         </div> )}
                        
                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container">
                                   <div className="">
                                        <div className="empowering-right" dangerouslySetInnerHTML={{ __html: InternetService?.data?.data?.smart_city?.smart_city_information_data[0]?.description }}>
                                        </div>

                                        
                                   </div>
                                   <div className="award-consider mb-5">
                                        <h4 className="mt-4"><strong>Why was Ishan Considered for the Award?</strong></h4>
                                        <div className="consider-row">
                                             <div className="left-list">
                                                  
                                                  <ul>
                                                       <li>
                                                            <span>Strategic Partnerships :</span>
                                                            Collaborated with industry leaders like Google Cloud and Versa Networks to offer secure, reliable, and low-latency connectivity solutions.
                                                       </li>
                                                       <li>
                                                            <span> Customer-Centric Approach :</span>
                                                           Focused on delivering 99.99% uptime, customized solutions, and seamless cloud integration to meet unique client needs.
                                                       </li>
                                                       <li>
                                                            <span>Pan-India Reach :</span>
                                                            Expanded operations to over 100+ locations, providing comprehensive coverage and connectivity across urban and rural areas.
                                                       </li>
                                                       <li>
                                                            <span>Sustainability and Smart Initiatives :</span>
                                                            Contributed to large-scale projects like Gujarat BharatNet and K-FON, and played a pivotal role in developing 10 smart cities across India.
                                                       </li>
                                                  </ul>
                                             </div>
                                             <a href= {pdfAward} download  className="right-img">
                                             <img src={awardIMG} alt="" className="img-fluid" />
                                             </a>
                                        </div>
                                   </div>
                                   <div className="award-consider mb-5">
                                        <h4 className="mt-4"><strong>Why this Matters?</strong></h4>
                                        <p className="para">
                                        At Ishan Technologies, we believe in delivering tailored, cutting-edge solutions that transform networks and fuel growth. This recognition solidifies our position as an industry leader, raising the bar for reliable, scalable, and future-ready solutions
                                        </p>
                                   </div>
                                   
                                   <div className="award-consider">
                                        <div className="advantage">
                                             <div class="advantage-inner mt-4">
                                                  <div class="advantage-row">
                                                       <div class="advantage-rowinner text-center">
                                                            <img src={kfOne} alt="" height="60" width="60"  />
                                                            <h4>Industry Excellence</h4>
                                                            <p>Awarded for innovation and quality in network services.</p>
                                                       </div>
                                                  </div>
                                                  <div class="advantage-row">
                                                  <div class="advantage-rowinner text-center">
                                                            <img src={kfTwo} alt="" height="60" width="60"  />
                                                            <h4>Customer-Centric</h4>
                                                            <p>Solutions designed for real-world challenges.</p>
                                                       </div>
                                                  </div>
                                                  <div class="advantage-row">
                                                       <div class="advantage-rowinner text-center">
                                                            <img src={kfThree} alt="" height="60" width="60"  />
                                                            <h4>Future-Ready</h4>
                                                            <p>Enabling digital transformation with agility and expertise.</p>
                                                       </div>
                                                  </div>


                                                  
                                                       
                                             </div>
                                        </div>
                                   </div>
                              </div>
   


                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>


                         <div className="client-experiences section-space">
                              <ClientExperiences homeData={InternetService} pageKey="smart_city" tag="smart_city" />
                         </div>

                         <div className="video hero-banner internet-banner ">
                              <img className="banner-img-web" type="home" src={btmBanner} height={60} width={60} />
                              <img className="banner-img-mob" type="home" src={btmBanner} />
                              <div className="banner-content">
                              <div className="content-blk">
                                   <h2 className="home-heading">Ready to Transform Your Network?</h2>
                                   <p className="para">Connect with us to discover how our award-winning solutions can take your business to the next leve</p>
                                   <a href="/contact">
                                   <button className="button hero-btn">Contact Us</button>
                                   </a>
                              </div>
                              </div>
                         </div>
                    

                    </>
               )} 
          </>
     );
}